import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import DefaultErrorPage from 'next/error'


const Session = () => {
  const router = useRouter();
  const [invalidSession, setInvalidSession] = useState(false);

  useEffect(() => {
    if (router.query) {
      const { sessionId, d, charge_id } = router.query;
      if (sessionId) {
        const query = { sessionId };
        if (d) {
          query.d = d;
        }
        if (charge_id) {
          query.charge_id = charge_id;
        }
        if (sessionId.startsWith('cs_')) {
          setInvalidSession(false);
          router.replace(
            { pathname: '/', query },
            `/${sessionId}`
          )
        } else {
          setInvalidSession(true);
        }
      }
    }
  }, [router])

  return (
    <div>
      {
        invalidSession && 
        <div>
          <Head>
            <title>Magpie Checkout</title>
            <meta name="robots" content="noindex"/>
          </Head>
          <DefaultErrorPage statusCode={404} />
        </div>
      }
    </div>
  )
}

export default Session;